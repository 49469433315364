import React, { useEffect, useState } from 'react';
import './app.scss'
function App() {
  const [tabsCurrent, settabsCurrent] = useState(1);
  const [inner, setinner] = useState({
    innerHeight: 0,
    innerWidth: 0
  })
  const [scrollWidth, setscrollWidth] = useState(0)

  const tabsItems = [
    {key: 1, label: '首页'},
    {key: 2, label: '渭来生活圈'},
    {key: 4, label: '联系我们'}
  ]
  useEffect(() => {
    const {innerWidth, innerHeight} = window;
    setinner({
      innerHeight,
      innerWidth
    })
    const node = document.getElementById('shouye')
    window.addEventListener('scroll', () => {handleScroll(node.clientHeight)})
  }, [])

  const handleScroll = (val) => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if (scrollTop < (val * 1.9) && scrollTop >= 0) {
      settabsCurrent(1)
    } else if (scrollTop >= (val * 1.9) && scrollTop < (val * 5)) {
      settabsCurrent(2)
    } else if (scrollTop >= (val * 5)) {
      settabsCurrent(4)
    }
  }

  const scrollToAnchor = (key) => {
    if (key === 1) {
      scrollView('shouye')
    } else if (key === 2) {
      scrollView('weilaishenghuoquan')
    } else if (key === 4) {
      scrollView('lianxiwomen')
    }
  }

  const scrollView = (name) => {
    let anchorElement = document.getElementById(name);
    anchorElement.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }

  return (
    <div className="home-page">
      <div className={`title ${tabsCurrent !== 1 ? 'green-title' : ''}`}>
        <div className='left'>
          <img src='images/logo.png' alt='' className='img' />
          <img src={tabsCurrent === 1 ? 'images/logo-text.png' : 'images/logo-text-green.png'} alt='' className='img-text' />
        </div>
        <div className='tabs-box'>
          {
            tabsItems.map(item => {
              return <div className={`tab-item ${item.key === tabsCurrent ? 'active' : ''}`} key={item.key} onClick={() => {
                settabsCurrent(item.key)
                scrollToAnchor(item.key)
              }}>{item.label}</div>
            })
          }
        </div>
      </div>
      <div className='box box1' id='shouye'>
        <img src='images/img-1.jpg' alt='' className='img'></img>
        <img src='images/xiala.png' alt='' className='xiala-img' onClick={() => {
          scrollView('shouye-2')
        }} />
        <div className='quan-box'></div>
        <div className='text-box'>
          <div className='text1'>渭来生活圈</div>
          <div className='text2'>市民综合服务小程序</div>
        </div>
        <div className='green-1'></div>
        <div className='green-2'></div>
      </div>
      <div className='box box2' id='shouye-2'>
        <img src='images/img-17.jpg' alt='' className='img'></img>
        <img src='images/xiala.png' alt='' className='xiala-img' onClick={() => {
          scrollView('weilaishenghuoquan')
          settabsCurrent(2)
        }} />
        <div className='quan-box'></div>
        <div className='text-box'>
          <div className='text1'>渭来生活圈</div>
          <div className='text2'>渭来生活圈是以数字化技术为依托，构建而成的城市综合服务平台，整合政府和社会信息服务，为市民提供政务服务、公共服务、停车缴费、生活服务、就医服务、旅游休闲等服务。</div>
        </div>
        <div className='green-1'></div>
        <div className='green-2'></div>
      </div>
      <div className='box box3' id='weilaishenghuoquan'>
        <img src="images/yinhao.png" alt='' className='img1' />
        <img src="images/img-3.png" alt='' className='img2' />
        <img src="images/weilaishenghuoquan.png" alt='' className='img3' />
        <img src="images/weilaishenghuoquan.png" alt='' className='img4' />
        <img src="images/img-2.png" alt='' className='img5' />
        <div className='text'>渭来生活圈是以数字化技术为依托，构建而成的城市综合服务平台，整合政府和社会信息服务，为市民提供政务服务、公共服务、停车缴费、生活服务、就医服务、旅游休闲等服务。</div>
        <img src='images/xiala.png' alt='' className='xiala-img' onClick={() => {
          scrollView('weilaishenghuoquan-2')
        }} />
      </div>
      <div className='box box4' id='weilaishenghuoquan-2'>
        <img src="images/yinhao-1.png" alt='' className='img1' />
        <img src="images/img-4.png" alt='' className='img2' />
        <img src="images/weilaishenghuoquan.png" alt='' className='img3' />
        <img src="images/weilaishenghuoquan.png" alt='' className='img4' />
        <img src="images/img-5.png" alt='' className='img5' />
        <div className='text'>渭来生活圈充分利用新技术推动新业态和模式创新，围绕便民服务，便民消费，整合城市信息和商业资源，接入家政、物业服务、教育培训、文化、休闲、餐饮等功能，提供市民周边服务和商品搜索、信息查询、生活缴费等地理导航免费服务，打造集约式发展生态圈。</div>
        <img src='images/xiala.png' alt='' className='xiala-img' onClick={() => {
          scrollView('weilaishenghuoquan-3')
        }} />
      </div>
      <div className='box box5' id='weilaishenghuoquan-3'>
        <div className='scroll-box' style={{width: `${inner.innerWidth}px`}}>
          <div className='center center-gundongquyu' style={{width: `${inner.innerWidth * 3}px`}}>
            <div className='box'>
              <div className='green-yuan' />
              <img src="images/img-11.png" className='img-1' alt="" />
              <img src="images/img-13.png" alt="" className='img-2' />
              <img src="images/yinhao.png" alt="" className='img-3' />
              <img src="images/img-12.png" alt="" className='img-4' />
              <div className='text'>供政务服务、公共服务、停车缴费、生活服务、就医服务、旅游休闲等服务快速查询。</div>
            </div>
            <div className='box'>
              <div className='green-yuan' />
              <img src="images/img-6.png" className='img-1' alt="" />
              <img src="images/img-8.png" alt="" className='img-2' />
              <img src="images/yinhao.png" alt="" className='img-3' />
              <img src="images/img-7.png" alt="" className='img-4' />
              <div className='text'>智能服务、快速查询服务、联系客服</div>
            </div>
            <div className='box'>
            <div className='green-yuan' />
              <img src="images/img-14.png" className='img-1' alt="" />
              <img src="images/img-10.png" alt="" className='img-2' />
              <img src="images/yinhao.png" alt="" className='img-3' />
              <img src="images/img-9.png" alt="" className='img-4' />
              <div className='text'>订单查询、优惠券管理、积分中心、常用工具</div>
            </div>
          </div>
        </div>
        <img src={(scrollWidth !== 0) ? 'images/left-active.png' : "images/left.png"} alt="" className='img-left' onClick={() => {
          if (scrollWidth === 0) {
            return
          }
          const node = document.getElementsByClassName('center-gundongquyu')[0];
          const scroll = scrollWidth - inner.innerWidth
          node.animate(
            [
              {transform: `translateX(-${scroll}px)`}
            ],
            {
              duration: 500,
              easing: 'linear',
              fill: 'forwards'
            }
          )
          setscrollWidth(scroll)
        }} />
        <img src={(scrollWidth !== inner.innerWidth * 2) ? 'images/right-active.png' : "images/right.png"} alt="" className='img-right' onClick={() => {
          if (scrollWidth === inner.innerWidth * 2) {
            return
          }
          const node = document.getElementsByClassName('center-gundongquyu')[0];
          const scroll = scrollWidth + inner.innerWidth
          node.animate(
            [
              {transform: `translateX(-${scroll}px)`}
            ],
            {
              duration: 500,
              easing: 'linear',
              fill: 'forwards'
            }
          )
          setscrollWidth(scroll)
        }} />
        <img src='images/xiala.png' alt='' className='xiala-img' onClick={() => {
          scrollView('lianxiwomen')
          settabsCurrent(4)
        }} />
      </div>
      <div className='box box6' id='lianxiwomen'>
        <img src="images/img-15.png" className='img-1' alt="" />
        <img src="images/img-16.png" className='img-2' alt="" />
        <div className='green-line' />
        <div className='tootip-text'>渭南大数据有限公司在城投集团的大力支持下，渭南市大数据有限公司应运而生。公司成立之初就秉承了立足渭南，专注大数据运营，树立大数据应用标杆的经营理想。推动政府大数据商业化运营，释放政府数据红利，最终建立健全基于政府大数据应用的全产业链。</div>
        <div className='footer'>
          <div className='top'>
            <div className='left'>
              <div className='text1'>联系我们</div>
              <div className='text2'>联系客服：wnsdsj@126.com</div>
            </div>
            <div className='right'>
              <div className='img-box'>
                <img src="images/erweima.png" className='img' alt="" />
              </div>
              <div className='text-box'>
                <div>微信扫码</div>
                <div>进入渭来生活圈</div>
              </div>
            </div>
          </div>
          <div className='bottom'>
            <div>渭南大数据有限公司</div>
            <div><a href="https://beian.miit.gov.cn/" rel="noreferrer" target='_blank'>陕ICP备2023008118号-1</a></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
